import {
  EventEmitter,
  forwardRef,
  Component,
  Output,
  OnInit,
  Input,
} from '@angular/core';
import { Validator } from 'src/app/shared/utils/validator';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'agv-input-file',
  template: `
    <div class="input-file" [ngClass]="{ disabled: disabled }">
      <div class="arquivo-wrapper">
        <div class="seleciona-arquivo">
          <label class="label_texto">
            {{ tituloBotaoFile }}
          </label>
          <div tabindex="0" class="button-image button-disabled">
            <input
              id="fileInput"
              type="file"
              style="display: none"
              [disabled]="disabled"
              (change)="onFileSelected($event)"
            />
            <label for="fileInput" class="label_texto">{{
              fileName ?? textoBotaoFile
            }}</label>
          </div>
        </div>
        <div *ngIf="base64String" class="image-file">
          <img
            *ngIf="tipo === 'image/png' || tipo === 'image/jpeg' || tipo === '' || tipo === undefined" 
            [src]="base64String"
            alt="Miniatura"
            class="image-min"
          />
          <agv-icon
            class="arquivo_default"
            *ngIf="tipo === 'application/pdf'"
            [name]="'icn_pdf'"
          ></agv-icon>
          <agv-icon
            class="arquivo_default"
            *ngIf="
              tipo ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            "
            [name]="'icn_docx'"
          ></agv-icon>
          <agv-icon
            class="arquivo_default"
            *ngIf="
              tipo ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            "
            [name]="'icn_xls'"
          ></agv-icon>
          <agv-icon
            *ngIf="base64String"
            class="arquivo_icn"
            [name]="'icn_limpar'"
            tabindex="0"
            title="Remover documento frente"
            (click)="limparAquivo($event)"
          ></agv-icon>
        </div>
        <div class="img-container" *ngIf="!base64String">
          <agv-icon [name]="'icn-foto'"></agv-icon>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .input-file {
        width: 100%;
        height: auto;
        max-width: 474px;

        .arquivo-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: auto;
          border: 1px solid var(--color-border-label-details);
          border-radius: 4px;
          padding: 18px 23px 18px 23px;

          .seleciona-arquivo {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;

            .label_texto {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding: 7px;
              cursor: pointer;
            }

            .button-image {
              border: 1px solid var(--color-border-label-details);
              padding: 2px 10px;
              border-radius: 4px;
              width: fit-content;
              transition: all 0.3s;

              .button-text {
                cursor: pointer;
              }

              &:hover {
                opacity: 0.8;
              }
            }
          }
          .image-file {
            display: flex;
            flex-direction: row;
            position: relative;

            .image-min {
              max-width: 76px;
              max-height: 56px;
              border: 1px solid #ddd;
              margin-top: 10px;
            }

            .arquivo_icn {
              color: white;
              cursor: pointer;
              width: 20px;
              margin-bottom: 22px;
            }

            .arquivo_default {
              position: absolute;
              height: 40px;
              width: 40px;
              z-index: -1;
              right: 1px;
              top: 18px;
            }
          }

          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            width: 48px;
            height: 48px;
          }
        }

        &.disabled {
          background-color: var(--color-gray-always-weak-disable) !important;

          .button-disabled {
            border: 1px solid var(--color-gray-300) !important;
            border-radius: 4px !important;
            cursor: not-allowed;

            label {
              cursor: not-allowed !important;
            }
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
  ],
})
export class InputFileComponent implements OnInit {
  fileName: string | undefined;
  tipo: string;
  @Input() tituloBotaoFile: string;
  @Input() textoBotaoFile: string;
  @Input() base64String: string;
  @Input() disabled: boolean;

  @Output() enviaFile: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
  ngOnInit(): void {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Verifica se é uma imagem JPEG/JPG
      const reader = new FileReader();

      reader.onload = () => {
        this.tipo = file.type;
        this.fileName = file.name;
        this.base64String = reader.result as string; // String Base64
        this.enviaFile.emit(this.base64String);
      };

      reader.onerror = (error) => {
        console.error('Erro ao ler o arquivo:', error);
      };

      reader.readAsDataURL(file);
    }
  }

  limparAquivo(evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      this.fileName = undefined;
      this.base64String = '';
    }
  }
}
