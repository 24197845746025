import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'agv-input-number',
  template: `
    <div
      role="group"
      [id]="id + '__input-number'"
      class="input-number"
      [ngClass]="{ disabled: blocked }"
    >
      <label
        class="input-number__label"
        [for]="id + '__input-number__number'"
        >{{ label }}</label
      >
      <input
        #inputNumber
        [id]="id + '__input-number__number'"
        [placeholder]="placeholder"
        class="input-number__input"
        (ngModelChange)="changeValor()"
        [disabled]="blocked"
        [(ngModel)]="value"
        [maxlength]="maxlength"
        (blur)="onTouched()"
        type="text"
      />
    </div>
  `,
  styles: [
    `
      .input-number {
        border: 1px solid var(--color-gray-always-weak);
        background-color: var(--color-white);
        padding: 10px 22px 10px 22px;
        flex-direction: column;
        position: relative;
        border-radius: 4px;
        display: flex;
        height: 65px;
        width: 100%;
        gap: 5px;

        &__label {
          color: var(--color-normal-grey);
          font-size: 0.75rem;
          font-weight: 400;
          display: flex;
        }

        &__input {
          background-color: transparent !important;
          font-size: 0.875rem !important;
          color: var(--color-black);
          font-weight: 400;
          border: none;
        }

        input::placeholder {
          color: var(--color-normal-grey);
        }

        &.disabled {
          background-color: var(--color-gray-always-weak-disable) !important;

          input {
            background-color: var(--color-gray-always-weak-disable) !important;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px var(--color-gray-always-weak-disable)
              inset !important;
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
})
export class InputNumberComponent implements OnInit {
  // Actions
  @Output() sendValor = new EventEmitter<any>();

  // Views
  @ViewChild('inputNumber') inputNumber: ElementRef;

  // Inputs - Boolean
  @Input() blocked: boolean = false;

  // Inputs - String
  @Input() placeholder: string = '';
  @Input() maxlength: string = '';
  @Input() value: string = '';
  @Input() label: string = '';
  @Input() id: string = '';

  private onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  constructor() {}

  ngOnInit() {}

  changeValor() {
    this.value = this.inputNumber.nativeElement.value;
    this.value = this.value.replace(/[^0-9.,]+/g, '');
    const parts = this.value.split(',');

    if (parts.length > 2) {
      this.value = parts[0] + ',' + parts.slice(1).join('').replace(',', '');
    }

    this.value = this.value.slice(0, 13);
    let formattedValue = '';
    if (this.value.includes(',')) {
      formattedValue =
        this.value
          .split(',')[0]
          .replace('.', '')
          .replace(/(?!^)(?=(\d{3})+(?!\d))/g, '.') +
        ',' +
        this.value.split(',')[1];
    } else {
      formattedValue = this.value
        .replace('.', '')
        .replace(/(?!^)(?=(\d{3})+(?!\d))/g, '.');
    }

    this.value = formattedValue;

    this.inputNumber.nativeElement.value = formattedValue;
    this.onChange(this.value);
    this.sendValor.emit(this.value);
  }
}
