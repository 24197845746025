import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'agv-input-text',
  template: `
    <div
      role="group"
      [id]="id + '__input-text'"
      class="input-text"
      [ngClass]="{ 'disabled': blocked }"
    >
      <label class="input-text__label" [for]="id + '__input-text__text'">{{
        label
      }}</label>
      <input
        #inputText
        [id]="id + '__input-text__text'"
        [placeholder]="placeholder"
        class="input-text__input"
        (ngModelChange)="changeValor()"
        (keypress)="validKeypress($event)"
        [disabled]="blocked"
        [(ngModel)]="value"
        [maxlength]="maxlength"
        (blur)="onTouched()"
      />
      <agv-icon
        *ngIf="deleteData"
        (click)="deleteInputValue()"
        class="input-text__icon"
        [id]="id + '__input-text__icon'"
        role="img"
        [name]="'icone_x'"
        title="Ícone fechar"
      ></agv-icon>
      <div
        *ngIf="searchInput"
        [id]="id + '__container_search-icon'"
        class="input-text__icon-lupa"
      >
        <hr class="linha" />
        <agv-icon
          (keypress)="validKeypress($event)"
          (click)="searchInputValue()"
          [id]="id + '__input-text__search-icon'"
          role="img"
          name="icn_lupa_input"
          title="Ícone buscar"
        ></agv-icon>
      </div>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .input-text {
        border: 1px solid var(--color-gray-always-weak);
        background-color: var(--color-white);
        padding: 10px 22px 10px 22px;
        flex-direction: column;
        position: relative;
        border-radius: 4px;
        display: flex;
        height: 65px;
        width: 100%;
        gap: 5px;

        &__label {
          color: var(--color-normal-grey);
          font-size: 0.75rem;
          font-weight: 400;
          display: flex;
        }

        &__input {
          background-color: transparent !important;
          font-size: 0.875rem !important;
          color: var(--color-black);
          font-weight: 400;
          border: none;
        }

        input::placeholder {
          color: var(--color-normal-grey);
        }

        &__icon {
          position: relative;
          left: calc(100% - 176px);
          bottom: 35px;
          cursor: pointer;
        }

        &__icon-lupa {
          display: flex;
          flex-direction: row;
          position: relative;
          left: calc(100% - 22px);
          bottom: 30px;
          cursor: pointer;
          align-items: center;


          .linha {
            height: 150%;
            width: 1px;
            transform: rotate(180deg);
            border: var(--color-gray-always-weak);
            background-color: var(--color-gray-always-weak);
            margin-right: 10px;
          }
        }

        &.disabled {
          background-color: var(--color-gray-always-weak-disable) !important;

          input {
            background-color: var(--color-gray-always-weak-disable) !important;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px var(--color-gray-always-weak-disable)
              inset !important;
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements OnInit {
  // Actions
  @Output() sendDeleteEvent = new EventEmitter<boolean>();
  @Output() sendValor = new EventEmitter<any>();

  // Views
  @Output() searchEvent = new EventEmitter<boolean>();
  @ViewChild('inputText') inputText: ElementRef;

  // Inputs - Boolean
  @Input() searchInput: boolean = false;
  @Input() deleteData: boolean = false;
  @Input() blocked: boolean = false;

  // Inputs - String
  @Input() value: string | undefined | null = '';
  @Input() placeholder: string = '';
  @Input() maxlength: string = '';
  @Input() label: string = '';
  @Input() type: string = '';
  @Input() id: string = '';

  private onChange: (value: any) => void = () => { };
  onTouched: () => void = () => { };
  constructor() { }

  ngOnInit() { }

  changeValor() {
    this.checkInputType();
    this.value = this.inputText.nativeElement.value;
    this.onChange(this.value);
    this.sendValor.emit(this.inputText.nativeElement.value);
  }

  deleteInputValue() {
    this.value = '';
    this.inputText.nativeElement.value = '';
    this.onChange(this.value);
    this.sendDeleteEvent.emit(true);
  }

  validKeypress(e: Event) {
    if (Validator.validaEventKeyPressClick(e) && this.searchInput) {
      this.searchInputValue();
    }
  }

  searchInputValue() {
    this.searchEvent.emit(true);
  }

  checkInputType() {
    switch (this.type) {
      case 'number':
        this.inputText.nativeElement.value =
          this.inputText.nativeElement.value.replace(/\D/g, '');
        break;
      default:
        break;
    }
  }

  writeValue(value: any): void {
    this.value = value || '';
    if (this.inputText && this.inputText.nativeElement) {
      this.inputText.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.blocked = isDisabled;
  }
}
