import { NoticiaModel } from '../core/models/noticias.model';
import { environment } from 'src/environments/environment';
import { Galeria } from '../core/models/galeria.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  private BASE_URL: string = environment.uri;

  constructor(private http: HttpClient) {}

  // Função para buscar notícias
  getTodasNoticias(
    pag: number,
    pagLimit: number
  ): Observable<{ noticias: NoticiaModel[] }> {
    return this.http.get<{ noticias: NoticiaModel[] }>(
      `${this.BASE_URL}v1/home/noticia/todas?pag=${pag}&limit=${pagLimit}`
    );
  }

  // Função para listar todas as imagens da galeria
  getImagensGaleria(): Observable<Galeria[]> {
    return this.http.get<Galeria[]>(
      `${this.BASE_URL}v1/home/noticia/galeria?pag=0&limit=999`
    );
  }

  // Função para publicar notícia
  publicar(noticia: NoticiaModel): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}v1/home/noticia`, noticia);
  }

  // Fução para alterar a notícia
  alterar(noticia: NoticiaModel): Observable<any> {
    return this.http.put<any>(
      `${this.BASE_URL}v1/home/noticia/${noticia.codigo}`,
      noticia
    );
  }

  // Função para agendar notícia
  agendar(noticia: NoticiaModel): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_URL}v1/home/noticia/rascunho`,
      noticia
    );
  }

  // Função para alterar notícia agendada
  alterarAgendamento(noticia: NoticiaModel): Observable<any> {
    return this.http.put<any>(
      `${this.BASE_URL}v1/home/noticia/rascunho${noticia.codigo}`,
      noticia
    );
  }

  // Função para listar as notícias
  getTodasNoticiasSec(
    pag: number,
    pagLimit: number
  ): Observable<{ noticias: NoticiaModel[] }> {
    return this.http.get<{ noticias: NoticiaModel[] }>(
      `${this.BASE_URL}v1/home/noticia/sec/todas?pag=${pag}&limit=${pagLimit}`
    );
  }

  validarDadosCampanha(cadastro: {
    cpf: string;
    fornecimento: string;
    idNoticia: number;
  }) {
    return this.http.post<any>(`${this.BASE_URL}v1/campanha/validar`, cadastro);
  }

  registrarCamapnha(cadastro: {
    nome: string,
    endereco: string,
    telefone: string,
    email: string,
    valor_leitura: number
  }, token: string) {
    return this.http.post<any>(
      `${this.BASE_URL}v1/campanha/registrar`,
      cadastro,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
      }
    );
  }
}
