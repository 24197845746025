import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NoticiaService } from 'src/app/services/noticia.service';
@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
})
export class NoticiaComponent implements OnInit {
  noticiasCards: any;

  constructor(private _ntService: NoticiaService, private router: Router) {}

  ngOnInit(): void {
    this._ntService.getTodasNoticiasSec(0, 30).subscribe((response: any) => {
      this.noticiasCards = response?.noticias;
    });
  }

  abrirNoticia(noticia: any) {
    this.router.navigateByUrl(`/sabesp-informa?slug=${noticia.slug}`);
  }
}
