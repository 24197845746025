<section id="pla_endereco-imovel_secao-principal" class="componentEnderecoImovel">
    <div class="componentEnderecoImovel__colunaDupla">
        <agv-input-text id="pla_endereco-imovel_estado" placeholder="UF" label="UF *"
            [value]="enderecoImovel?.estadoCodigo" [blocked]="true"
            (sendValor)="setDadoEndereco($event, 'estadoCodigo')"></agv-input-text>
        <agv-input-select id="pla_endereco-imovel_toponimo" [structor]="opcoesMunicipio"
            [blocked]="enderecoImovel?.municipioBlock ?? false" [value]="enderecoImovel.municipioDescr"
            (sendValor)="setDadoEndereco($event, 'municipioDescr')"
            (enviaValorObjeto)="setDadoEndereco($event, 'municipioId')"></agv-input-select>
    </div>
    <agv-input-select id="pla_endereco-imovel_toponimo" [structor]="opcoesToponimo"
        [blocked]="enderecoImovel?.toponimoBlock ?? false" [value]="enderecoImovel.toponimoDescr"
        (sendValor)="setDadoEndereco($event, 'toponimoDescr')"
        (enviaValorObjeto)="setDadoEndereco($event, 'toponimoId')"></agv-input-select>
    <section>
        <agv-input-text id="pla_endereco-imovel_logradouro" placeholder="Digite o endereço" label="Endereço *"
            [blocked]="enderecoImovel?.logradouroBlock ?? false" [value]="enderecoImovel.logradouroDescr"
            (sendValor)="setDadoEndereco($event, 'logradouroDescr')"></agv-input-text>
        <div *ngIf="abreListaEndereco" (clickOutside)="abreListaEndereco = false" class="componentEnderecoImovel__listaOpcoes">
            <div *ngFor="let endereco of lstaEndereco">
                <p class="opcao" (click)="selecionaLogradouro(endereco)"><span>{{endereco.logradouro}} - {{endereco.bairro}}</span></p>
            </div>
        </div>
    </section>
    <div class="componentEnderecoImovel__colunaDuplaInvertida">
        <agv-input-select id="pla_endereco-imovel_tipo-civico" [structor]="opcoesTipoCivico"
            [blocked]="enderecoImovel?.tipoCivicoBlock ?? false" [value]="enderecoImovel.tipoCivicoDescr?? ''"
            (sendValor)="setDadoEndereco($event, 'tipoCivicoDescr')"
            (enviaValorObjeto)="setDadoEndereco($event, 'tipoCivicoCodigo')"></agv-input-select>
        <agv-input-text id="pla_endereco-imovel_civico" placeholder="Digite o nº" label="Número *"
            [blocked]="enderecoImovel?.numeroBlock ?? false" [value]="enderecoImovel.tipoCivicoId"
            (sendValor)="setDadoEndereco($event, 'tipoCivicoId')"></agv-input-text>
    </div>
    <agv-input-text id="pla_endereco-imovel_complemento" placeholder="Digite o complemento" label="Complemento"
        [blocked]="enderecoImovel?.complementoBlock ?? false" [value]="enderecoImovel.complemento"
        (sendValor)="setDadoEndereco($event, 'complemento')"></agv-input-text>
    <agv-input-text id="pla_endereco-imovel_bairro" placeholder="Digite o bairro" label="Bairro *"
        [blocked]="enderecoImovel?.bairroBlock || cepInexistente ?? false" [value]="enderecoImovel.bairroDescr"
        (sendValor)="setDadoEndereco($event, 'bairroDescr')"></agv-input-text>
</section>
