import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agv-input-hora',
  template: `<div [id]="id + '__agv-input-hora'" class="agv-input-hora">
    <label
      [id]="id + '__agv-input-hora__label'"
      class="agv-input-hora__label"
      [for]="id + '__agv-input-hora__input'"
      >{{ label }}</label
    >
    <input
      [id]="id + '__agv-input-hora__input'"
      [placeholder]="placeholder"
      class="agv-input-hora__input"
      type="text"
      [(ngModel)]="value"
      (ngModelChange)="filtraHoras()"
    />
    <agv-icon
      [id]="id + '__agv-input-hora__icon'"
      class="agv-input-hora__icon"
      [name]="'arrow_black'"
      [ngClass]="{ opened: showList }"
      (click)="showList = !showList"
    ></agv-icon>
    <div
      *ngIf="showList"
      [id]="id + '__agv-input-hora__group'"
      class="agv-input-hora__group"
    >
      <ul
        [id]="id + '__agv-input-hora__group__itens'"
        class="agv-input-hora__group__itens"
      >
        <li
          *ngFor="let hora of horasFiltradas; let index = index"
          [id]="id + '__agv-input-hora__group__itens__item-{{index}}'"
          class="agv-input-hora__group__itens__item"
          (click)="action(hora)"
        >
          {{ hora }}
        </li>
      </ul>
    </div>
  </div>`,
  styles: [
    `
      .agv-input-hora {
        border: 1px solid var(--color-gray-always-weak);
        background-color: var(--color-white);
        padding: 10px 22px 10px 22px;
        flex-direction: column;
        position: relative;
        border-radius: 4px;
        display: flex;
        height: 65px;
        width: 100%;
        gap: 5px;

        &__label {
          color: var(--color-normal-grey);
          font-size: 0.75rem;
          font-weight: 400;
          display: flex;
        }

        &__input {
          background-color: transparent !important;
          font-size: 0.875rem !important;
          color: var(--color-black);
          font-weight: 400;
          border: none;
          width: 95%;
        }

        &__icon {
          transition: all 0.2s;
          position: absolute;
          cursor: pointer;
          height: 20px;
          right: 22px;
          width: 20px;
          top: 22px;

          &.opened {
            transform: rotate(180deg);
          }
        }

        &__group {
          width: 100%;
          background: var(--color-white);
          position: absolute;
          padding: 0px 20px;
          max-height: 300px;
          overflow: auto;
          top: 65px;
          left: 0px;

          &__itens {
            flex-direction: column;
            list-style: none;
            display: flex;

            &__item {
              border-top: 1px solid var(--color-gray-weak);
              padding: 10px 0px;
              cursor: pointer;
            }
          }
        }
      }
    `,
  ],
})
export class InputHoraComponent implements OnInit {
  // Input String
  @Input() placeholder: string;
  @Input() value: string;
  @Input() label: string;
  @Input() id: string;

  // Actions
  @Output() sendValue = new EventEmitter<string>();

  // Boolean
  showList: boolean = false;

  // Lista
  horas: string[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];
  horasFiltradas: string[] = this.horas;

  ngOnInit(): void {}

  // Função para emitir a hora selecionada
  action(hora: string) {
    this.horasFiltradas = this.horas;
    this.value = hora;
    this.sendValue.emit(hora);
    this.showList = false;
  }

  // Função para filtrar as horas
  filtraHoras() {
    this.horasFiltradas = this.horas.filter((h) => h.includes(this.value));
  }
}
