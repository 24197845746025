import {
  SimpleChanges,
  Component,
  OnChanges,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'agv-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnInit, OnChanges {
  // Input String
  @Input() placeholder: string;
  @Input() label: string;
  @Input() value: string;
  @Input() id: string;

  // Input Boolean
  @Input() blockPrev: boolean;
  @Input() blockNext: boolean;
  @Input() blocked: boolean;

  // Input Date
  @Input() minDate: Date | undefined;
  @Input() maxDate: Date | undefined;

  // String
  dateSelected: string = '';

  // Listas
  months: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  days: string[] = [];

  // Boolean
  show: boolean = false;

  // Date
  date: Date = new Date();
  dateSelectedDate: Date;

  // Actions
  @Output() sendValue = new EventEmitter<string>();

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['value'] !== undefined &&
      changes['value'].currentValue !== 'undefined' &&
      changes['value'].currentValue !== changes['value'].previousValue
    ) {
      if (this.value.includes('GMT')) {
        let data = new Date(this.value);
        this.dateSelectedDate = data;
        this.dateSelected = `${
          data.getDate() < 10 ? '0' + data.getDate() : data.getDate()
        }/${
          data.getMonth() < 10 ? '0' + data.getMonth() : data.getMonth()
        }/${data.getFullYear()}`;
      } else if (this.value.includes('T')) {
        let data = new Date(this.value);
        this.dateSelectedDate = data;
        this.dateSelected = `${
          data.getDate() < 10 ? '0' + data.getDate() : data.getDate()
        }/${
          data.getMonth() < 10 ? '0' + (data.getMonth()+1) : (data.getMonth()+1)
        }/${data.getFullYear()}`;
      } else if (this.value.includes('-')) {
        this.dateSelected = `${this.value.split('-')[0]}/${
          this.value.split('-')[1]
        }/${this.value.split('-')[2]}`;
        this.dateSelectedDate = new Date(
          parseInt(this.value.split('-')[2]),
          parseInt(this.value.split('-')[1]) - 1,
          parseInt(this.value.split('-')[0])
        );
      } else {
        this.dateSelectedDate = new Date(
          parseInt(this.value.split('/')[0]),
          parseInt(this.value.split('/')[1]) - 1,
          parseInt(this.value.split('/')[2])
        );
      }
    }
  }

  changeDate(e: any) {
    this.sendValue.emit(this.value);
  }

  showCalender() {
    this.show = !this.show;
    this.getDays();
  }

  getYear(): string {
    return `${this.date.getFullYear()}`;
  }

  getMonth(): string {
    return `${this.months[this.date.getMonth()]}`;
  }

  getDays(): {
    class: string;
    value: string;
    prevMonth: boolean;
    nextMonth: boolean;
  }[] {
    let today = new Date();
    let firstDayOfMonth = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      1
    ).getDay();
    let lastDayOfMonth = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    ).getDate();
    let lastDayOfNextMonth = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      lastDayOfMonth
    ).getDay();
    let lastDayOfLastMonth = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      0
    ).getDate();

    let list: {
      class: string;
      value: string;
      prevMonth: boolean;
      nextMonth: boolean;
    }[] = [];
    for (let index = firstDayOfMonth; index > 0; index--) {
      let day = lastDayOfLastMonth - index + 1;
      list.push({
        class: (this.minDate !== undefined &&
          new Date(
            this.date.getFullYear(),
            this.date.getMonth()-1,
            day
          ).getTime() < this.minDate.getTime()) || (this.maxDate !== undefined &&
            new Date(
              this.date.getFullYear(),
              this.date.getMonth()-1,
              day
            ).getTime() > this.maxDate.getTime())
        ? 'disabled'
        :'inactive',
        value: `${day}`,
        prevMonth: true,
        nextMonth: false,
      });
    }
    for (let index = 1; index <= lastDayOfMonth; index++) {
      if (
        this.dateSelectedDate !== undefined &&
        this.dateSelectedDate !== new Date()
      ) {
        list.push({
          class: `${
            this.dateSelectedDate.getDate() === index &&
            this.dateSelectedDate.getMonth() === this.date.getMonth() &&
            this.dateSelectedDate.getFullYear() === this.date.getFullYear()
              ? 'active'
              : (this.minDate !== undefined &&
                new Date(
                  this.date.getFullYear(),
                  this.date.getMonth(),
                  index
                ).getTime() < this.minDate.getTime()) || (this.maxDate !== undefined &&
                  new Date(
                    this.date.getFullYear(),
                    this.date.getMonth(),
                    index
                  ).getTime() > this.maxDate.getTime())
              ? 'disabled'
              : ''
          }`,
          value: `${index}`,
          prevMonth: false,
          nextMonth: false,
        });
      } else {
        list.push({
          class: `${
            today.getDate() === index &&
            today.getMonth() === this.date.getMonth() &&
            today.getFullYear() === this.date.getFullYear()
              ? 'active'
              : (this.minDate !== undefined &&
                new Date(
                  this.date.getFullYear(),
                  this.date.getMonth(),
                  index
                ).getTime() < this.minDate.getTime()) || (this.maxDate !== undefined &&
                  new Date(
                    this.date.getFullYear(),
                    this.date.getMonth(),
                    index
                  ).getTime() > this.maxDate.getTime())
              ? 'disabled'
              : ''
          }`,
          value: `${index}`,
          prevMonth: false,
          nextMonth: false,
        });
      }
    }
    for (let index = lastDayOfNextMonth; index < 6; index++) {
      let day = index - lastDayOfNextMonth + 1;
      list.push({
        class: 'inactive',
        value: `${day}`,
        prevMonth: false,
        nextMonth: true,
      });
    }
    return list;
  }

  prevMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
  }

  nextMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0);
  }

  selectDate(day: string, month: number) {
    this.dateSelected = `${parseInt(day) < 10 ? '0' + day : day}/${
      this.date.getMonth() + 1 + month < 10
        ? '0' + (this.date.getMonth() + 1 + month)
        : this.date.getMonth() + 1 + month
    }/${this.date.getFullYear()}`;
    this.dateSelectedDate = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + month,
      parseInt(day)
    );
    this.date = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + month,
      parseInt(day)
    );
    this.getDays();
    this.showCalender();
    this.sendValue.emit(this.dateSelected);
  }
}
