import { Observable, map } from 'rxjs'; 
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { ClienteCompleto, InfoArquivo } from "../core/models/service/cadastroCliente";

interface IRequestConfirmarUsuario {
  cpf: string;
  fornecimento: string;
}

@Injectable({
    providedIn: 'root',
})

export class AtualizacaoCadastralService{
  private readonly BASE_URL = `${environment.uri}`;
  private readonly API_KEY = `${environment.apikey}`;
  
  constructor(private http: HttpClient) {}
  
  confirmarUsuario(
    dados: IRequestConfirmarUsuario,
    recaptchaToken: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      "X-ReCaptcha-Token": `${recaptchaToken}`,
      "apikey": this.API_KEY,
    });
    return this.http
      .post<any>(`${this.BASE_URL}v1/primeiro/acesso/cliente/valida`, dados, {
        headers,
      }).pipe(
        map((response: any) => {
          if (response !== null) {
            return {
              token: response.token,
              status: HttpStatusCode.Ok
            }
          } else {
            return {
              token: '',
              status: HttpStatusCode.NoContent
            }
          }
        })
      );
  }

  getInfosDocumentoFrente(fileBase64: any, authToken: string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${authToken}`);
    headers.append('Accept', 'application/json');
    headers.append("apikey", this.API_KEY);
    return this.http.post<InfoArquivo>(
      `${this.BASE_URL}v1/primeiro/acesso/atualizacao/ocr`, fileBase64, { headers }
    );
  }

  getInfosDocumentoVerso(fileBase64: any, authToken: string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${authToken}`);
    headers.append('Accept', 'application/json');
    headers.append("apikey", this.API_KEY);
    return this.http.post<InfoArquivo>(
      `${this.BASE_URL}v1/primeiro/acesso/atualizacao/ocr2`, fileBase64, { headers }
    );
  }

  atualizaDadosCliente(dadosCliente:ClienteCompleto, authToken: string) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    headers.append("apikey", this.API_KEY);
    
    return this.http.post<any>(
      `${this.BASE_URL}v1/primeiro/acesso/atualizacao/confirmar`, dadosCliente, { headers }
    );
  }
}

